<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Albañilería',

    metaInfo: { title: 'Albañilería' },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        'albanileriacontent',
        // 'theme-features',
        // 'affiliates',
        // 'newsletter',
        // 'pro-features',
        // 'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'Albañilería',
      },
    },
  }
</script>
